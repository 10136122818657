import React from "react";
import { GoLocation } from "react-icons/go";

function AreaHit({ hit, onClick }) {
  //  console.log(hit)
  return (
    <div className=" group flex cursor-pointer flex-row items-center space-x-3 bg-white px-5 py-2 hover:bg-zinc-100">
      <GoLocation className=" h-5 w-5 text-ula-primary" />
      <div className=" flex flex-col">
        <span className=" cursor-pointer   text-gray-800">{hit.areaName}</span>
        <span
          onClick={() => {}}
          className=" cursor-pointer text-sm font-light  text-gray-600 group-hover:text-gray-600"
        >
          {hit.areaName}, {hit.townName}
        </span>
      </div>
    </div>
  );
}

export default AreaHit;
