/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from "react";
import Head from "next/head";
import Link from "next/link";
import algoliasearch from "algoliasearch/lite";
import { AiFillStar, AiOutlineLoading3Quarters } from "react-icons/ai";
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { load } from "@fingerprintjs/botd";
import * as Toast from "@radix-ui/react-toast";
import { AnimatePresence, motion } from "framer-motion";
import * as Dialog from "@radix-ui/react-dialog";
import { isMobile } from "react-device-detect";
import {
  InstantSearch,
  Index,
  Configure,
  useInstantSearch,
  useHits,
  useSearchBox,
} from "react-instantsearch-hooks-web";
import TownHit from "../components/search/Hit/TownHit";
import PropertyHit from "../components/search/Hit/PropertyHit";
import AreaHit from "../components/search/Hit/AreaHit";
import ListerHit from "../components/search/Hit/ListerHit";
import Image from "next/image";
import { FiArrowUpRight, FiSearch } from "react-icons/fi";
import { useRouter } from "next/router";
import { useClickOutside } from "@react-hooks-library/core";
import { useAuthModal } from "../components/auth/modalcontext";
import { useAuth } from "../auth/AuthProvider";
import { dbAdmin, rtdbAdmin } from "../lib/firebase-admin";
import { getOGimage, getUrl } from "../utils/getUrl";
// import RedoAnimText from "../components/UI/anim/RedoAnimtext";
// import enableMessaging from "../scripts/enableMessaging";
import cookie from "js-cookie";
import dynamic from "next/dynamic";
import { capture } from "../utils/analytics/capture";

const RedoAnimText = dynamic(() =>
  import("../components/UI/anim/RedoAnimtext")
);
const HomePropertyHit = dynamic(() =>
  import("../components/search/PlacePropertyHit/HomePropertyHit")
);

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_SEARCH_APP_ID,
  process.env.NEXT_PUBLIC_SEARCH_API_KEY
);

const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      // return Promise.resolve({
      //   results: requests.map(() => ({
      //     hits: [],
      //     nbHits: 0,
      //     nbPages: 0,
      //     page: 0,
      //     processingTimeMS: 0,
      //     hitsPerPage: 0,
      //     exhaustiveNbHits: false,
      //     query: "",
      //     params: "",
      //   })),
      // });
    }
    return algoliaClient.search(requests);
  },
};

function AllEmptyQueryBoundary({
  children,
  fallback,

  resultsOpen,
  searchRef,
}) {
  const { indexUiState, results, status } = useInstantSearch();

  if (!indexUiState.query) {
    return fallback;
  }
  // if (indexUiState.query  && results.nbHits === 0) {
  //   return <span className=" animate-bounce  bg-white text-black">...</span>;
  // }

  if (!results.__isArtificial && results.nbHits === 0) {
    return <></>;
  }

  return (
    <>
      {resultsOpen ? (
        <div
          ref={searchRef}
          className=" absolute mt-[4.0rem] flex w-full flex-col space-y-2  divide-y overflow-y-auto  rounded-b-md  rounded-t-sm border border-gray-300 bg-white lg:mt-[4.53rem]"
        >
          {children}
        </div>
      ) : null}
    </>
  );
}

function CustomAreaHits(props) {
  const { hits, results, sendEvent } = useHits(props);
  return (
    <>
      {results.nbHits > 0 ? (
        <div className=" flex flex-col bg-white pt-4">
          <h2 className=" mb-1 px-5 ">
            <span className="  text-xs font-semibold uppercase text-gray-600">
              Areas
            </span>
          </h2>
          {hits.map((hit) => {
            return <AreaHit key={hit.objectID} hit={hit} source="HOME" />;
          })}
        </div>
      ) : null}
    </>
  );
}

function CustomTownHits(props) {
  const { hits, results, sendEvent } = useHits(props);
  //console.log("Towns  : "+results.nbHits)
  return (
    <>
      {results.nbHits > 0 ? (
        <div className=" flex flex-col bg-white pt-4">
          <h2 className=" mb-1 px-5 ">
            <span className="  text-xs font-semibold uppercase text-gray-600">
              Town
            </span>
          </h2>
          {hits.map((hit) => {
            return (
              <TownHit
                key={hit.objectID}
                hit={hit}
                sendEvent={sendEvent}
                source="HOME"
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}

function CustomListerHits(props) {
  const { hits, results, sendEvent } = useHits(props);
  //  console.log(hits);
  return (
    <>
      {results.nbHits > 0 ? (
        <div className=" flex flex-col rounded-b-md bg-white pb-1 pt-4">
          <h2 className=" mb-1 px-5 ">
            <span className="  text-xs font-semibold uppercase text-gray-600">
              Agent
            </span>
          </h2>
          {hits.map((hit) => {
            return (
              <ListerHit
                key={hit.objectID}
                sendEvent={sendEvent}
                hit={hit}
                source="HOME"
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}

function CustomPropertyHits(props) {
  const { hits, results, sendEvent } = useHits(props);
  // console.log("Props : "+results.nbHits)
  return (
    <>
      {results.nbHits > 0 ? (
        <div className=" flex flex-col rounded-b-md bg-white pb-1 pt-4">
          <h2 className=" mb-1 px-5 ">
            <span className="  text-xs font-semibold uppercase text-gray-600">
              Home
            </span>
          </h2>
          {hits.map((hit) => {
            return (
              <PropertyHit
                key={hit.objectID}
                sendEvent={sendEvent}
                hit={hit}
                source="HOME"
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}
function CustomSearchBox({
  bot,
  setBot,
  // handleRecaptcha,
  executeScroll,
  searchRef,
  inputShown,
  setInputShown,
  setResultsOpen,
  resultsOpen,
  executeScrollToTitle,
}) {
  const { query, refine, clear } = useSearchBox({
    queryHook,
  });
  const { status } = useInstantSearch();
  const [focused, setFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);

  return (
    <motion.div
      className={
        " flex w-full flex-row rounded-md border-0 border-blue-600   " +
        (focused ? " shadow-xl" : " shadow-xl")
      }
    >
      {!inputShown ? (
        <div
          onClick={() => {
            setInputShown(true);
          }}
          className={
            " flex w-full cursor-text   space-x-1  rounded-l-md border border-r-0 border-gray-400 bg-white   py-4 pl-5 pr-10  text-lg text-gray-700 placeholder-gray-700   caret-ula-primary outline-none placeholder:text-base focus:border-gray-400 focus:ring-0 disabled:bg-gray-300 lg:py-5 lg:pl-6 lg:placeholder:text-lg"
            // (!inputShown ? " flex" : " hidden")
          }
        >
          <>
            {!bot ? (
              <>
                <span className="  flex flex-shrink-0">Find houses in</span>
                {/* <div className="flex flex-row space-x-1"> */}
                <RedoAnimText delay={1} />
                <CursorBlinker />
                {/* </div> */}
              </>
            ) : (
              <>Contact hello@ula.africa if search is disabled</>
            )}
          </>
        </div>
      ) : null}
      {inputShown ? (
        <input
          type="search"
          ref={searchRef}
          autoComplete="off"
          onFocus={() => {
            setFocused(true);
            setResultsOpen(true);
            executeScrollToTitle();
            // handleRecaptcha();
            load()
              .then((botd) => {
                return botd.detect();
              })
              .then((result) => {
                // console.log(result.bot);
                setBot(result.bot);

                capture({
                  category: "search",
                  event: "bot_detect",
                  properties: {
                    bot_detected: result.bot,
                  },
                });
              })
              .catch((error) => console.error(error));
          }}
          onBlur={() => {
            setFocused(false);
            if (!searchQuery) {
              setInputShown(false);
            }
          }}
          onChange={(event) => {
            setSearchQuery(event.target.value);
            if (event.target.value.length === 1) {
              // handleRecaptcha();
              load()
                .then((botd) => {
                  return botd.detect();
                })
                .then((result) => {
                  // console.log(result.bot);
                  setBot(result.bot);

                  capture({
                    category: "search",
                    event: "bot_detect",
                    properties: {
                      bot_detected: result.bot,
                    },
                  });
                })
                .catch((error) => console.error(error));
            }
            if (event.target.value.length >= 3) {
              setResultsOpen(true);
              refine(event.currentTarget.value);

              capture({
                category: "search",
                event: "search_done",
                properties: {
                  searchTerm: event.currentTarget.value,
                },
              });
            }
            if (event.target.value.length === 0) {
              clear();
            }
          }}
          // process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
          disabled={bot}
          id="home-search"
          placeholder={
            bot
              ? "Contact hello@ula.africa if search is disabled"
              : "Enter location.."
          }
          className={
            "  flex w-full rounded-l-md border border-r-0 border-gray-400  py-4  pl-5 pr-10 text-lg  placeholder-gray-700 caret-ula-primary outline-none placeholder:text-base focus:border-2 focus:border-r-0 focus:border-ula-primary focus:ring-0 disabled:bg-gray-300 lg:py-5 lg:pl-6 lg:placeholder:text-lg "
            // +
            // (inputShown ? " flex" : " hidden")
          }
        ></input>
      ) : null}
      <button
        aria-label="Search"
        onClick={() => {
          if (searchQuery?.length === 1) {
            handleRecaptcha();
          }
          if (searchQuery?.length >= 3) {
            setResultsOpen(true);

            refine(searchQuery);

            capture({
              category: "search",
              event: "search_button_click",
              properties: {
                searchTerm: searchQuery,
              },
            });
          } else {
            capture({
              category: "search",
              event: "search_button_click",
              properties: {
                searchTerm: false,
              },
            });
          }
        }}
        className={
          " flex w-24 rounded-r-md  border border-l-0   bg-white px-2   py-2  font-medium  text-white   lg:w-24 lg:px-3" +
          (focused
            ? " border-2 border-ula-primary "
            : " border border-gray-400 ")
        }
      >
        <div className=" flex h-full w-full flex-row items-center  justify-center rounded-lg bg-ula-blue">
          {status === "loading" || status === "stalled" ? (
            <AiOutlineLoading3Quarters className=" h-4 w-4 animate-spin" />
          ) : (
            <FiSearch className=" h-5 w-5" />
            // <span>Search</span>
          )}
        </div>
      </button>
    </motion.div>
  );
}
let timerId = undefined;
function queryHook(query, search) {
  if (timerId) {
    clearTimeout(timerId);
  }
  //550 prev
  timerId = setTimeout(() => search(query), 0);
}

const cursorVariants = {
  blinking: {
    opacity: [0, 0, 1, 1],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatDelay: 0,
      ease: "linear",
      times: [0, 0.5, 0.5, 1],
    },
  },
};

function CursorBlinker() {
  return (
    <motion.div
      variants={cursorVariants}
      animate="blinking"
      className=" inline-block     text-gray-700 "
    >
      |
    </motion.div>
  );
}

export default function Home({
  allTowns,
  popularTowns,
  featuredTown,
  featuredTownProps,
}) {
  const [bot, setBot] = useState(false);
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const [loadingResults, setLoadingResults] = useState(false);
  const router = useRouter();
  const popTowns = JSON.parse(popularTowns);
  const featuredT = JSON.parse(featuredTown);
  const featuredTownP = JSON.parse(featuredTownProps);

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState(null);
  const waitlistRef = useRef(null);

  const titleRef = useRef(null);
  const searchRef = useRef(null);
  const [inputShown, setInputShown] = useState(false);

  const [resultsOpen, setResultsOpen] = useState(true);
  const contextAuth = useAuth();
  const user = contextAuth.user;
  const claims = contextAuth.claims;

  const authModal = useAuthModal();

  useClickOutside(searchRef, () => {
    setResultsOpen(false);
    searchRef.current.blur();
  });

  // const handleRecaptcha = useCallback(() => {
  //   if (!executeRecaptcha) {
  //     console.log("Execute recaptcha not yet available");
  //     return;
  //   }
  //   return executeRecaptcha("enquiryFormSubmit")
  //     .then((gReCaptchaToken) => {
  //       fetch("/api/recap", {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json, text/plain, */*",
  //           "Content-Type": "application/json",
  //         },

  //         body: JSON.stringify({
  //           gRecaptchaToken: gReCaptchaToken,
  //         }),
  //       })
  //         .then((res) => res.json())
  //         .then((resp) => {
  //           if (resp?.status === "success") {
  //             // console.log("success");
  //             // console.log(resp?.message.score);
  //             if (!bot) {
  //               setBot(false);
  //
  //             }
  //             return;
  //           } else {
  //             //console.log("no oerrd")
  //             console.log(resp?.message.score);
  //             // setBot(true);
  //             return;
  //           }
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [executeRecaptcha]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getApps();

      // console.log("routed to here", router.pathname);
      return;
    }

    // if (executeRecaptcha) {
    //   load()
    //     .then((botd) => {
    //       return botd.detect();
    //     })
    //     .then((result) => {
    //       // console.log(result.bot);
    //       setBot(result.bot);
    //

    //       if (!result.bot) {
    //         handleRecaptcha();
    //       }
    //     })
    //     .catch((error) => console.error(error));
    // }
    return () => {};
  }, []);

  const executeScroll = async () =>
    await waitlistRef.current.scrollIntoView({
      behavior: "smooth",
    });
  const executeScrollToTitle = async () =>
    titleRef.current.scrollIntoView({
      behavior: "smooth",
    });

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (isClient) {
    window.onscroll = function () {
      myFunction();
      // myFun();
    };

    var navbar = document.getElementById("navbar");

    function myFunction() {
      if (window.pageYOffset >= window.innerHeight * 0.9) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }
  }
  const [appInstalled, setAppInstalled] = useState(false);

  const getApps = async () => {
    try {
      let apps = await navigator.getInstalledRelatedApps();
      if (apps.length > 0) {
        // apps.forEach((app) => {
        //   console.log(app.id, app.platform, app.url);
        // });
        setAppInstalled(apps[0].id === "com.ulaafrica.app");
      } else {
        setAppInstalled(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [loadingProperty, setLoadingProperty] = useState(null);

  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Ula Africa",
    alternateName: ["Ula", "Ula App"],
    url: "https://ula.africa",
    sameAs: [
      "https://facebook.com/@ulafrica",
      "https://instagram.com/ulaafrica",
      "https://linkedin.com/company/ularealafrica",
    ],
  };

  const appSchema = {
    "@context": "https://schema.org",
    "@type": "MobileApplication",
    name: "Ula",
    operatingSystem: "ANDROID, IOS",
    applicationCategory: "LifestyleApplication",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      ratingCount: "5",
      reviewCount: "1",
    },
    review: [
      {
        "@type": "Review",
        author: {
          "@type": "Person",
          name: "Donivard Gitonga",
        },
        datePublished: "2023-09-22",
        reviewBody:
          "Home search has become dramatically more easier now.. Ula will be an invaluable platform to help me find my next house.",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5",
        },
      },
    ],
    offers: {
      "@type": "Offer",
      price: "0",
    },
  };

  const breadSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@type": "Webpage",
          "@id": `${getUrl()}`,
          name: "Ula Africa",
          url: `${getUrl()}`,
        },
      },
      // {
      //   "@type": "ListItem",
      //   position: 2,
      //   item: {
      //     "@type": "WebPage",
      //     "@id": "https://jika.co/blog",
      //     name: "Blog",
      //     url: "https://jika.co/blog",
      //   },
      // },
      // {
      //   "@type": "ListItem",
      //   position: 3,
      //   name: "ASD",
      //   url: `${getUrl()}/blog/`,
      // },
    ],
  };

  useEffect(() => {
    if (searchRef.current && inputShown) {
      // console.log("hey foc");
      searchRef.current.focus();
    }
  }, [inputShown]);
  const [installAppModal, setInstallAppModal] = useState(true);
  const [topBannerShown, setTopBannerShown] = useState(true);

  const topBanner = cookie.get("install_banner");

  return (
    <div>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <title>Ula Africa: Home Search, Simplified</title>
        <link rel="canonical" href={`${getUrl()}`} />
        <meta
          name="description"
          content="Search for real estate and rental homes wherever you live in Kenya. Connect with 1000+ agents and companies in your next home search"
        />
        <meta name="og:title" content="Ula Africa: Home Search, Simplified" />
        <meta
          name="og:description"
          content="Search for real estate and rental homes wherever you live in Kenya. Connect with 1000+ agents and companies in your next home search"
        />
        <meta name="og:image" content={`${getOGimage()}`} />
        <meta property="og:url" content={`${getUrl()}`} />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(appSchema) }}
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:site" content="@digitalocean" /> */}
        <meta
          name="twitter:title"
          content="Ula Africa: Home Search, Simplified"
        />
        {/* <meta
          name="twitter:description"
          content="Search for real estate and rental homes wherever you live in Kenya. Connect with 1000+ agents and companies in your next home search"
        /> */}
        <meta name="twitter:image" content={`${getOGimage()}`} />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(breadSchema) }}
        />
      </Head>

      <Dialog.Root
        open={
          (topBanner === undefined || topBanner !== "hide") &&
          installAppModal &&
          isClient
        }
        modal={isMobile}
        // forceMount
      >
        <AnimatePresence>
          {(topBanner === undefined || topBanner !== "hide") &&
          installAppModal &&
          isClient ? (
            <Dialog.Portal forceMount>
              <Dialog.Overlay forceMount>
                <motion.div
                  key="overla"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.5 }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.05,
                    },
                  }}
                  className="fixed inset-0  z-20 flex  h-screen items-center justify-center bg-black   opacity-50 lg:hidden"
                ></motion.div>
              </Dialog.Overlay>
              <Dialog.Content
                onInteractOutside={() => {
                  // console.log("out");
                }}
                onOpenAutoFocus={(e) => {
                  e.preventDefault();
                }}
                forceMount
              >
                <motion.div
                  key="moda"
                  initial={{ y: 500 }}
                  animate={{ y: 0 }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 40,
                    duration: 0.4,
                  }}
                  exit={{
                    y: 500,
                    // scale: 0.8,
                    transition: {
                      duration: 0.05,
                    },
                  }}
                  className=" fixed  bottom-0  z-20 flex w-[100vw]  flex-col items-center space-y-3 rounded-t-lg bg-white px-6 py-8 lg:hidden"
                >
                  <div className=" flex h-12  w-12 items-center justify-center rounded-full bg-ula-primary">
                    <span className=" font-eastman text-lg font-medium text-white ">
                      ula
                    </span>
                  </div>
                  <div className=" flex flex-col items-center space-y-1">
                    <span className=" text-xl font-semibold">
                      Use the Ula App
                    </span>
                    <span className=" text-gray-700">
                      The best experience is on our mobile app
                    </span>
                  </div>

                  {appInstalled ? (
                    <a
                      rel="nofollow noreferrer"
                      aria-label="Open Mobile App"
                      href="https://homes.ula.africa/app"
                      className="   flex w-full justify-center rounded-md bg-ula-primary px-5 py-4 font-semibold  text-gray-200"
                    >
                      Open App
                    </a>
                  ) : (
                    <a
                      aria-label="Open Store"
                      rel="nofollow noreferrer"
                      href={
                        isClient &&
                        /iPad|iPhone|iPod/.test(navigator?.userAgent)
                          ? "https://apps.apple.com/ke/app/ula-find-homes/id6452316629"
                          : "https://play.google.com/store/apps/details?id=com.ulaafrica.app"
                      }
                      className="   flex w-full  items-center  justify-center rounded-md bg-ula-primary py-4 text-center  font-semibold text-gray-200"
                    >
                      <span>Use App</span>
                    </a>
                  )}
                  <a
                    href=""
                    rel="nofollow noreferrer"
                    aria-label="Close Download Modal"
                    onClick={(e) => {
                      e.preventDefault();
                      setInstallAppModal(!installAppModal);
                      cookie.set("install_banner", "hide", {
                        expires: appInstalled ? 90 : 15,
                      });
                    }}
                    className=" font-medium text-ula-primary"
                  >
                    Continue on the website
                  </a>
                </motion.div>
                <Dialog.Title />
                <Dialog.Description />
                <Dialog.Close />
              </Dialog.Content>
            </Dialog.Portal>
          ) : null}
        </AnimatePresence>
      </Dialog.Root>

      <div className=" flex flex-col   bg-ula-primary-background lg:flex-row">
        <div className="relative order-2 mt-4 flex h-auto w-full flex-col items-start justify-center  px-0 pb-8 lg:order-1 lg:h-[85vh] lg:w-3/5 lg:px-24 lg:pb-24  ">
          <div className=" relative z-10     flex h-auto w-full flex-col space-y-4 px-6  lg:mb-0 lg:w-auto lg:items-center lg:space-y-8 lg:px-3  lg:pt-0">
            <div className=" flex   w-full flex-col space-y-2  lg:items-start lg:space-y-5 ">
              <h1
                ref={titleRef}
                onClick={() => {
                  // setInstallAppModal(!installAppModal);
                }}
                className="text-secondary relative text-4xl font-bold lg:text-left lg:text-7xl lg:font-semibold"
              >
                Home search, <br className=" hidden lg:flex"></br>
                <span className=" decoration-wav underline-none text-ula-primar    decoration-ula-primary decoration-4  underline-offset-4 lg:decoration-[6px] lg:underline-offset-[10px]">
                  simplified.
                </span>
                {/* <div className=" absolute -z-10 -mt-[0.8rem] ml-0 h-[0.7rem]   w-40  rounded-full  bg-ula-background lg:-ml-[0.3rem] lg:-mt-5 lg:flex  lg:h-[1rem] lg:w-[21rem] lg:bg-ula-primary ">
                  {" "}
                </div> */}
              </h1>
              <h2 className=" lg:text-secondary text-secondary font-medium lg:text-2xl">
                Find homes close to you in<br className=" hidden lg:flex"></br>{" "}
                Nairobi, <br className=" flex lg:hidden"></br> Kiambu and
                Kajiado easily
              </h2>
            </div>
            {/* <div className=" flex w-full flex-row items-end justify-start space-x-2">
              <span className=" text-gray-800">Do a quick search</span>
              <BsArrowDownCircleFill className=" h-4 w-4 animate-bounce cursor-pointer text-ula-primary" />
            </div> */}

            <div className=" flex h-auto w-full flex-col justify-center space-y-2 pr-0 lg:h-12 lg:flex-row lg:justify-center lg:space-y-0 lg:pr-0">
              <InstantSearch
                searchClient={searchClient}
                indexName={process.env.NEXT_PUBLIC_PROPERTIES_INDEX}
              >
                <div className=" relative flex   w-full flex-col  rounded-md lg:w-[100%]  ">
                  <CustomSearchBox
                    bot={bot}
                    executeScroll={executeScroll}
                    setBot={setBot}
                    searchRef={searchRef}
                    inputShown={inputShown}
                    setInputShown={setInputShown}
                    // handleRecaptcha={handleRecaptcha}
                    setResultsOpen={setResultsOpen}
                    resultsOpen={resultsOpen}
                    executeScrollToTitle={executeScrollToTitle}
                  />

                  {popTowns.length > 0 ? (
                    <div className=" mt-5 flex flex-row space-x-1 lg:space-x-2">
                      <span className=" hidden select-none text-gray-800 lg:flex">
                        Popular searches:
                      </span>
                      <span className=" select-none text-gray-700 lg:hidden">
                        Popular:
                      </span>
                      <div className=" scrollbar-hide flex flex-row space-x-2 overflow-x-scroll">
                        {popTowns.map((town, index) => {
                          return (
                            // <Link
                            //   href={{
                            //     pathname: `/town/${town.townSlug}`,
                            //   }}
                            //   prefetch={false}
                            //   key={town.townID}
                            //   as={`/town/${town.townSlug}`}
                            //   passHref
                            // >
                            <div
                              key={town.townID}
                              className=" group flex flex-row space-x-0"
                            >
                              <a
                                href={`/town/${town.townSlug}`}
                                key={town.townID}
                                target="_blank"
                                className=" cursor-pointer  font-semibold  text-black transition-colors duration-300 hover:font-bold hover:underline"
                              >
                                {town.townName}
                              </a>
                              <FiArrowUpRight className=" mt-1 h-3 w-3 text-black transition duration-300 group-hover:scale-110" />
                              {index !== popTowns.length - 1 ? "" : ""}{" "}
                            </div>
                            // </Link>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}

                  <AllEmptyQueryBoundary
                    fallback={null}
                    resultsOpen={resultsOpen}
                    searchRef={searchRef}
                  >
                    <Index
                      indexName={process.env.NEXT_PUBLIC_TOWNS_INDEX}
                      indexId="towns"
                    >
                      <Configure hitsPerPage={4} />
                      <CustomTownHits />
                    </Index>

                    <Index
                      indexName={process.env.NEXT_PUBLIC_LISTERS_INDEX}
                      indexId="listers"
                    >
                      <Configure hitsPerPage={3} />
                      <CustomListerHits />
                    </Index>

                    <Index
                      indexName={process.env.NEXT_PUBLIC_PROPERTIES_INDEX}
                      indexId="properties"
                    >
                      <Configure hitsPerPage={3} />
                      <CustomPropertyHits />
                    </Index>
                  </AllEmptyQueryBoundary>
                </div>
              </InstantSearch>
            </div>
          </div>
        </div>

        <div className=" relative w-full  overflow-x-clip lg:order-2 lg:w-2/5 lg:overflow-x-visible">
          {/* <div className="  absolute ml-8 mt-[2.7rem]  h-48 w-48   rounded-full    bg-blue-300 lg:-ml-1 lg:mt-8  lg:flex lg:h-[26rem] lg:w-[26rem] ">
            {" "}
          </div> */}
          {/* <div className=" absolute ml-8 mt-[3.5rem] h-48 w-48   rounded-full  bg-ula-primary  lg:-ml-[23rem] lg:mt-20 lg:flex  lg:h-[6rem] lg:w-[6rem] lg:bg-ula-background ">
            {" "}
          </div> */}
          <div className="  absolute   bottom-16 right-32 z-10 rounded-md border-0 border-gray-600 bg-white shadow-2xl lg:bottom-20  lg:right-48 lg:ml-0 lg:mt-0 lg:h-48 lg:w-64">
            <Image
              className=" h-full w-full rounded-md  object-cover"
              layout="fill"
              priority
              alt="juja houses for sale - ula africa"
              src="/image/hero/juja-houses-ula-africa.png"
            />
          </div>
          <div className=" absolute -bottom-4 right-6 z-10 ml-[13rem]   h-[7rem]  w-[9rem] rounded-md  bg-transparent  shadow-2xl  lg:absolute lg:bottom-40  lg:right-8   lg:h-40 lg:w-56">
            <Image
              className=" h-full w-full  rounded-md object-contain  lg:object-cover"
              layout="fill"
              priority
              alt="ruiru houses for rent - ula africa"
              src="/image/hero/ruiru-houses-ula-africa.png"
            />
          </div>
          <div className="absolute  right-0 top-12 z-10   -ml-[8rem]  h-[7rem]  w-[9rem] rounded-md  bg-white shadow-2xl lg:left-2  lg:top-[9.7rem]  lg:h-40 lg:w-56">
            <Image
              className=" h-full w-full rounded-md  object-cover"
              layout="fill"
              priority
              alt="westlands houses for sale - ula africa"
              src="/image/hero/westlands-houses-ula-africa.png"
            />
          </div>
          <div className="  relative ml-5 mt-8 h-48 w-80 rounded-md bg-gray-200 shadow-md  lg:-ml-8 lg:mt-20 lg:h-[22rem] lg:w-[30rem]">
            <Image
              className=" h-full w-full rounded-md  object-cover"
              layout="fill"
              priority
              alt="home search simplified - ula africa"
              src="/image/home_search_simplified_ula_africa.jpg"
            />
          </div>

          {/* <div className="  relative -mt-16 ml-[13rem] h-24 w-40  rounded-md  bg-gray-300  shadow-lg lg:-mt-[9rem] lg:ml-40 lg:h-48 lg:w-72">
              <Image
                className=" h-full w-full rounded-md  object-cover"
                layout="fill"
                priority
                alt="cities, home search simplified_ula africa"
                src="/image/cities_ula_africa.webp"
              />
            </div> */}
        </div>
      </div>

      {/* We have Kshs 2000 that should be in your pocket! */}
      {/* {(user || !user) &&
      (!claims?.user || claims?.user) &&
      !claims?.manager ? (
        <div className="flex flex-col justify-between space-y-10  bg-gray-100 px-5 py-1  lg:flex-row lg:space-y-0 lg:bg-zinc-50 lg:px-32 lg:pb-16 lg:pt-16">
          <div className=" flex w-full flex-col items-center justify-between space-y-4 rounded-md bg-[#80FF80] px-6 py-8 shadow-sm lg:h-32 lg:flex-row lg:space-y-0 lg:px-10 lg:py-2 ">
            <div className=" flex flex-col justify-center">
              <span className=" text-lg font-bold text-teal-950 lg:text-xl">
                Invite Agent and Get Kshs 2000
              </span>

              <span className=" text-teal-950">
                {" "}
                Invite an agent and get rewarded when they list their first
                property on Ula
              </span>
            </div>
            <button
              onClick={() => {
                router.push("/ula-app?referral=1");
              }}
              className=" hidden w-full rounded-md bg-gray-900   px-16 py-4 font-semibold text-gray-200 lg:flex lg:w-auto lg:py-4"
            >
              Get referral link
            </button>
            <div className=" flex w-full items-start lg:hidden">
              <a
                onClick={() => {
                 
                }}
                href="https://homes.ula.africa/app"
                className=" flex  rounded-md bg-gray-900 px-12   py-4 font-semibold text-gray-200 lg:hidden lg:w-auto lg:py-4"
              >
                Get referral link
              </a>
            </div>
          </div>
        </div>
      ) : null} */}

      <div className=" flex flex-col space-y-5  bg-ula-gray px-5 py-12 lg:flex-col lg:space-y-5 lg:px-20 lg:py-20">
        {/* <span className=" font-medium text-center">Find homes in Kilimani</span> */}
        <div className=" flex flex-col space-y-1">
          <div className=" mb-2 flex items-start ">
            <div className=" flex  flex-row items-center justify-center space-x-2 rounded-full bg-ula-primary px-4 py-2">
              <AiFillStar className=" text-gray-100" />
              <h2
                // onClick={() => {

                // }}
                className="   text-xs font-semibold uppercase text-gray-100"
              >
                Featured town
              </h2>
            </div>
          </div>
          <div className=" flex w-full flex-row items-center justify-between">
            <h3 className=" text-xl font-semibold lg:text-2xl">
              Homes for rent in {featuredT.townName}
            </h3>

            <Link
              href={{
                pathname: `/town/${featuredT.townSlug}`,
              }}
              prefetch={false}
              as={`/town/${featuredT.townSlug}`}
              passHref
            >
              <a
                href={`/town/${featuredT.townSlug}`}
                className=" cursor-pointer text-sm font-semibold text-ula-primary  hover:underline lg:text-base"
              >
                View all
              </a>
            </Link>
          </div>
        </div>
        <div className="   flex  h-[17rem]  w-full snap-x flex-row gap-x-5 overflow-x-auto lg:grid   lg:grid-cols-4 lg:gap-x-5">
          {/* <div className=" h-56 w-[90%] bg-green-400 flex flex-shrink-0"></div>
          <div className=" h-56 w-[90%] bg-green-400 flex flex-shrink-0"></div> */}
          {featuredTownP.map((hit) => {
            return (
              <HomePropertyHit
                key={hit.propertyID}
                hit={hit}
                loadingProperty={loadingProperty}
                setLoadingProperty={setLoadingProperty}
                dataSource="FIRESTORE"
              />
            );
          })}
        </div>
        {/* <Link
          href={{
            pathname: `/town/${featuredT.townSlug}`,
          }}
          as={`/town/${featuredT.townSlug}`}
          passHref
        >
          <span className=" cursor-pointer text-center font-semibold text-ula-primary hover:underline lg:hidden">
            View all homes in {featuredT.townName}
          </span>
        </Link> */}
      </div>

      <div className=" flex flex-col">
        <div
          className={
            " flex w-full flex-col items-center justify-center space-y-0 bg-white pt-12 lg:space-y-2 " +
            (user && claims.manager ? " lg:pt-24" : " lg:pt-24")
          }
        >
          <h2 className=" px-12 text-center text-2xl font-semibold lg:text-4xl">
            Goodbye long home searches
          </h2>
          <h3 className=" text-center text-gray-800 lg:text-lg">
            Find your home with ease.<br></br>
            {/* The future of home search has
            arrived <br></br> — and it’s here to stay. */}
          </h3>
        </div>
        <div className="flex flex-col justify-between space-y-10  bg-white px-5 py-12  lg:flex-row lg:space-y-0 lg:bg-white lg:px-32 lg:pb-32">
          <div className="flex h-88  w-full flex-col items-center rounded-b-md rounded-t-md border border-gray-300 bg-ula-background shadow-md lg:w-80 ">
            <div className=" relative h-3/4 w-full overflow-x-hidden rounded-t-md px-5 py-4">
              <div className=" absolute top-8 -ml-10  h-10  w-40 rounded-md bg-white shadow-sm lg:top-5">
                <Image
                  className="absolute h-full w-full rounded-md object-contain"
                  src="/image/find-homes/kilimani.png"
                  layout="fill"
                  alt="Ula Africa Town Filters"
                />
              </div>
              <div className=" absolute right-2  top-2 h-10 w-40 rounded-md bg-white shadow-sm">
                <Image
                  className="absolute h-full w-full rounded-md object-contain"
                  src="/image/find-homes/ruiru.png"
                  layout="fill"
                  alt="Ula Africa Town Filters_2"
                />
              </div>
              <div className=" absolute bottom-6  left-3 h-10 w-40 rounded-md bg-white shadow-sm">
                <Image
                  className="absolute h-full w-full rounded-md object-contain"
                  src="/image/find-homes/westlands.png"
                  layout="fill"
                  alt="Ula Africa Town Filters_3"
                />
              </div>
              <div className=" absolute bottom-20 right-12 h-24 w-56 rounded-md border-0 border-blue-200 bg-white shadow-lg lg:right-12">
                <Image
                  className="absolute h-full w-full rounded-md object-cover"
                  src="/image/find-homes/props.png"
                  layout="fill"
                  alt="Ula Africa Homes Filters"
                />
              </div>
              <div className=" absolute bottom-3  right-3 h-12 w-32 rounded-md bg-white shadow-sm">
                <Image
                  className="absolute h-full w-full rounded-md object-contain"
                  src="/image/find-homes/toprate.png"
                  layout="fill"
                  alt="Ula Africa Agent Filters"
                />
              </div>
            </div>
            <div className=" flex h-1/3 w-full flex-col justify-center rounded-b-md bg-white px-6 lg:items-center lg:px-4">
              <h2 className=" text-xl font-bold text-gray-800 lg:text-2xl">
                Find homes
              </h2>
              <h3 className=" text-base font-medium text-gray-700 lg:text-center">
                Search homes around your town
              </h3>
            </div>
          </div>
          <div className="flex h-88 w-full  flex-col items-center rounded-b-md rounded-t-md  border border-gray-300 bg-ula-background shadow-md lg:w-80 ">
            <div className=" relative h-3/4 w-full overflow-x-hidden rounded-t-md">
              <div className=" absolute right-16 top-24 h-[1.6rem] w-20 rounded-md bg-white shadow lg:right-4 lg:top-32  lg:mt-3 lg:h-6 lg:w-24">
                <Image
                  className="absolute h-full w-full rounded-md object-contain"
                  src="/image/fav.png"
                  layout="fill"
                  alt="Ula Africa Favorited Homes"
                />
              </div>
              <div className=" absolute right-10 top-8 h-12  w-24 rounded-md bg-white shadow  lg:right-8 lg:top-16 lg:h-12 lg:w-24">
                <Image
                  className="absolute h-full w-full rounded-md object-contain"
                  src="/image/markettype.png"
                  layout="fill"
                  alt="Ula Africa Market Type Filters"
                />
              </div>
              <div className=" absolute  left-10 top-5  h-32 w-32 rounded-md  bg-white shadow lg:left-12 lg:top-12 lg:z-0 lg:h-32 lg:w-32">
                <Image
                  className="absolute  rounded-md object-contain lg:h-32 lg:w-32 lg:object-cover"
                  src="/image/home-type-filters.png"
                  layout="fill"
                  alt="Ula Africa Home Type Filters"
                />
              </div>

              <div className="  absolute bottom-4 left-4 h-16 w-72 rounded-md shadow lg:bottom-2 lg:h-[3.0rem] lg:w-56">
                <Image
                  className="absolute  h-full w-full rounded-md lg:object-cover"
                  src="/image/price-filter.png"
                  layout="fill"
                  alt="Ula Africa Home Price Filters"
                />
              </div>
            </div>
            <div className="flex h-1/3 w-full flex-col justify-center rounded-b-md bg-white px-6 lg:items-center lg:px-4">
              <h2 className=" text-xl font-bold text-gray-800 lg:text-2xl">
                Narrow down
              </h2>
              <h3 className=" text-base font-medium text-gray-700 lg:text-center">
                Fine-tune search to your exact home options
              </h3>
            </div>
          </div>
          <div className="flex h-88 w-full flex-col items-center rounded-b-md rounded-t-md  border border-gray-300  bg-ula-background shadow-md  lg:w-80 ">
            <div className=" relative h-3/4 w-full rounded-t-md px-5">
              <div className=" relative top-12 h-40 w-full rounded-md bg-white shadow ">
                <Image
                  className="absolute h-full w-32 rounded-md object-contain lg:object-contain"
                  src="/image/chats.png"
                  layout="fill"
                  alt="Ula Africa Chat With Agent"
                />
              </div>
              <div className=" absolute left-16 top-3 h-12 w-12 rounded-md shadow">
                <Image
                  className="  h-full w-full rounded-md"
                  src="/image/chatcount.png"
                  layout="fill"
                  alt="Ula Africa Chat with Agent_unread"
                />
              </div>
            </div>
            <div className=" flex h-1/3 w-full flex-col justify-center rounded-b-md bg-white px-6 lg:items-center lg:px-4 ">
              <h2 className=" text-xl font-bold text-gray-800 lg:text-2xl">
                Move in
              </h2>
              <h3 className=" text-base font-medium text-gray-700 lg:text-center">
                Begin the rent or buying process
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex flex-col space-y-5 bg-ula-background-light px-5 py-16 lg:flex-row lg:space-x-2 lg:space-y-0 lg:px-32 lg:py-32">
        <div className=" mb-3 flex w-full flex-col space-y-3 lg:mb-10 lg:w-1/2 lg:pr-48  ">
          <h2 className=" text-3xl font-semibold  lg:text-5xl">
            {/* next home  */}
            <span className="underline- text-ula-primary decoration-ula-primary">
              {" "}
              Your next home
            </span>{" "}
            is waiting
          </h2>
          <h3 className=" pr-16 lg:pr-5 lg:text-lg">
            We take care of the hard work and match you with homes that suit
            your needs and preferences.<br></br> Find your dream home without
            the hassle
          </h3>
        </div>
        <div className=" relative flex   lg:h-[60vh] lg:w-1/2 lg:items-end  lg:pl-8 ">
          <div className=" absolute z-0 mb-6 ml-5 mt-5 h-72  w-10/12 rounded-3xl bg-ula-corn lg:-mb-8 lg:ml-8 lg:h-[60vh] lg:w-3/4"></div>
          <div className=" relative z-10 h-72 w-10/12 py-5 lg:h-[60vh]  lg:w-11/12 ">
            <Image
              src="/image/Mask-Group.png"
              layout="fill"
              className=" relative w-full rounded-2xl object-cover object-left-top lg:object-contain"
              alt="Ula Africa Family Homes"
            />
          </div>
        </div>
      </div>

      <div className=" flex flex-col space-y-3 px-5 pb-24 pt-16  lg:flex-row lg:space-x-2 lg:px-32 lg:py-32">
        <div className=" relative order-2 mt-8 flex lg:order-1 lg:mt-0  lg:h-[60vh] lg:w-1/2  lg:items-end ">
          <div className=" absolute z-0 mb-6 ml-5 mt-5 h-72 w-10/12 rounded-3xl  bg-ula-background lg:ml-10 lg:mt-0 lg:h-[55vh]"></div>
          <div className=" relative z-10 h-72 w-10/12 py-5  lg:h-[90%]">
            <Image
              src="/image/AdobeKeys.jpeg"
              layout="fill"
              className=" relative  h-full w-full rounded-3xl object-cover"
              alt="Ula Africa Couple With Keys"
            />
          </div>
        </div>

        <div className=" order-1 flex flex-col space-y-3 lg:w-1/2 lg:pl-48 ">
          <h2 className=" text-3xl font-semibold lg:text-5xl">
            Homes{" "}
            <span className="underline- text-ula-primary decoration-ula-primary">
              {" "}
              for you,
            </span>{" "}
            friends and family
          </h2>
          <h3 className=" pr-16 lg:pr-5 lg:text-lg">
            Whether you&apos;re just starting out, a student, or with your
            family, we have the perfect home.<br></br> And we make the entire
            process as smooth as possible
          </h3>
        </div>
      </div>

      <div className=" flex flex-col space-y-8  bg-ula-background-light px-5 py-16  lg:flex-row lg:space-x-2 lg:px-32 lg:py-32">
        <div className=" relative h-72 py-5 lg:h-[60vh] lg:w-1/2">
          <Image
            src="/image/lady.png"
            layout="fill"
            className=" relative  h-full w-full rounded-3xl   bg-ula-secondary object-cover object-left"
            alt="Ula Africa Homes App"
          />
        </div>

        <div className=" flex flex-col space-y-3 lg:w-1/2 lg:pl-48 ">
          <h2 className=" text-3xl font-semibold lg:text-5xl">
            A{" "}
            <span className="underline- text-ula-primary decoration-ula-primary">
              {" "}
              broad
            </span>{" "}
            selection of homes in Kenya
          </h2>
          <h3 className=" pr-16 lg:pr-5 lg:text-lg">
            With search tools that allow you to filter homes by location,
            budget, size, and more.<br></br> This with data backed
            recommendations to help you make informed decisions about renting or
            buying your home.
          </h3>
        </div>
      </div>

      {/* <div className=" h-auto space-y-8 px-4 py-16 lg:space-y-10 lg:px-24 lg:pb-16 lg:pt-32">
        <h2 className=" px-10 text-center text-2xl font-semibold lg:px-0 lg:text-3xl">
          What are people saying about Ula?
        </h2>
        <div className=" flex w-full flex-row space-x-8 overflow-x-auto pr-4 lg:w-auto lg:pr-0">
          <div className=" flex w-[85%] flex-shrink-0 flex-col space-y-6 divide-y divide-gray-400 rounded-xl bg-zinc-100 px-10 py-10 lg:w-1/2 lg:flex-shrink lg:px-12 lg:py-12 ">
            <h3 className=" text-2xl font-bold leading-8  lg:text-3xl lg:leading-relaxed">
              “Ula has been there every step of the way. With Ula, I’ve found
              the perfect home for my family at an incredibly affordable rate.
              Kudos!”
            </h3>
            <div className=" flex flex-col pt-6">
              <div className=" flex flex-row items-center space-x-2">
                <div className="flex flex-row space-x-1">
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                </div>
              </div>
              <span className=" text-gray-600">Play Store Review</span>
              <span className=" font-semibold">Donivard Gitonga</span>
            </div>
          </div>
          <div className="flex w-11/12 flex-shrink-0 flex-col space-y-6 divide-y divide-gray-400 rounded-xl bg-zinc-100 px-10 py-10 lg:w-1/2 lg:flex-shrink lg:px-12 lg:py-12 ">
            <h3 className=" text-2xl font-bold leading-8  lg:text-3xl lg:leading-relaxed">
              “Home search has become dramatically more easier now..<br></br>{" "}
              Ula will be an invaluable platform to help me find my next house.”
            </h3>
            <div className=" flex flex-col pt-6">
              <div className=" flex flex-row items-center space-x-2">
                <div className="flex flex-row space-x-1">
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                  <AiFillStar className="h-5 w-5 text-yellow-600" />
                </div>
              </div>
              <span className=" text-gray-600">Play Store Review</span>
              <span className=" font-semibold">David Kanyingi</span>
            </div>
          </div>
        </div>
      </div> */}

      <div className=" h-auto px-0 py-0 lg:h-[90vh] lg:px-0 lg:pb-0 lg:pt-0">
        <div className=" flex h-full flex-col  space-y-3 bg-ula-primary lg:flex-row lg:rounded-none">
          <div className=" flex flex-col  space-y-2 px-5 pt-16 lg:w-1/2 lg:justify-center lg:space-y-6 lg:px-32 lg:py-24">
            <h2 className=" text-3xl font-bold text-white lg:text-5xl">
              Find homes, <br></br> wherever you are
            </h2>

            <div className=" flex flex-col items-center justify-center space-y-0 lg:flex-row lg:space-x-6">
              <div className=" relative hidden h-32 w-32 flex-shrink-0 bg-transparent lg:flex">
                <Image
                  className=" h-full w-full object-cover "
                  layout="fill"
                  src="/image/store-link.png"
                  alt="Ula Africa in play and app stores"
                />
              </div>

              <div className=" flex flex-col space-y-4">
                <h3 className=" text-base text-white lg:text-xl">
                  <span className=" hidden lg:flex">Scan to download</span>{" "}
                  <span className=" lg:hidden">Download</span> the Ula africa
                  app and discover a simpler way to find homes almost anywhere.
                </h3>
                <div className=" flex w-full flex-row  items-center space-x-5 lg:hidden">
                  <a
                    href="https://apps.apple.com/ke/app/ula-find-homes/id6452316629"
                    className="relative  flex  h-12 w-32"
                  >
                    <Image
                      className=" object-contain  "
                      src="/image/app-store-logo.svg"
                      layout="fill"
                      alt="Ula iOS App"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ulaafrica.app"
                    className=" relative h-[3.8rem] w-[8.7rem]"
                  >
                    <Image
                      className="  h-full w-full object-cover "
                      src="/image/play-badge.png"
                      layout="fill"
                      alt="Ula Android App"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" relative ml-0 flex h-full w-full pb-5 lg:ml-0 lg:w-1/2  lg:items-end  lg:justify-center lg:py-10 ">
            <div className="  relative      h-104  w-full lg:h-[75vh]">
              <Image
                className=" h-full w-full object-contain   lg:object-contain "
                src="/image/store.png"
                layout="fill"
                alt="Ula App"
              />
            </div>
          </div>
        </div>
      </div>

      <div className=" flex  h-auto flex-col space-y-5 bg-ula-gray px-4 py-12 lg:space-y-8 lg:px-24 lg:pb-48  lg:pt-24">
        <div className=" flex flex-col space-y-1">
          <h2 className=" hidden  text-lg font-semibold text-gray-800">
            Apartments and Houses for Rent in Kenya
          </h2>
          <span className="  text-xl font-semibold text-gray-900 lg:text-2xl">
            Homes and Real Estate by Towns
          </span>
          <h3 className=" text-gray-800">
            Browse apartments, view photos. Connect with agents to schedule a
            viewing in these{" "}
            <Link href="/town" passHref>
              <a className=" hover:underline">towns</a>
            </Link>
            .
          </h3>
        </div>
        <div className=" grid  grid-cols-2 gap-x-4 gap-y-2 rounded-md bg-white  px-8 py-8 font-medium text-ula-primary  shadow lg:grid-cols-4 lg:gap-y-6">
          {allTowns
            .sort((a, b) => a.townName.localeCompare(b.townName))
            .map((town) => {
              // console.log(town.townID)
              return (
                <Link
                  key={town.townID}
                  href={{
                    pathname: `/town/${town.townSlug}`,
                  }}
                  prefetch={false}
                  as={`/town/${town.townSlug}`}
                  passHref
                >
                  <a href={`/town/${town.townSlug}`}>
                    <h4
                      key={town.townID}
                      className=" cursor-pointer text-base font-semibold no-underline  decoration-2  underline-offset-4 transition duration-500 hover:underline hover:decoration-2 lg:text-base "
                    >
                      Apartments for Rent in {town.townName}
                    </h4>
                  </a>
                </Link>
              );
            })}
        </div>
      </div>

      <div className="  flex flex-col bg-white px-0 py-8 lg:bg-white lg:px-24 lg:py-8 lg:pb-16">
        <div className=" relative flex h-auto w-full flex-col items-center justify-center rounded-none py-[7.3rem] lg:h-32 lg:py-0  ">
          <Image
            layout="fill"
            unoptimized
            className="  z-0 h-full w-full rounded-none object-cover brightness-[0.50] "
            src="https://images.pexels.com/photos/12976798/pexels-photo-12976798.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Ula Africa Hodari Agent"
          />
          <div className="absolute  flex h-full w-full flex-col items-start justify-start px-5  lg:flex-row lg:items-center lg:justify-between lg:px-16">
            <div className=" flex flex-col pb-4 pt-8 lg:pb-8">
              <h2 className=" text-xl font-semibold text-gray-100">
                Ula for Real Estate Agents and Companies
              </h2>
              <h3 className="text-gray-100">
                Showcase your business to a larger tailored market. Get even
                more qualified leads
              </h3>
            </div>
            <Link href="/hodari" passHref>
              <a>
                <button className=" w-full rounded-md bg-ula-primary px-24 py-4 font-semibold tracking-wide text-gray-200 shadow lg:w-auto lg:px-20 lg:py-4">
                  Sign up
                </button>
              </a>
            </Link>
          </div>
        </div>
      </div>

      <div
        id="navbar"
        className={
          " relative bottom-0   flex  hidden    w-full  items-center justify-center  pb-8 pt-5 transition-all duration-300 ease-in-out lg:justify-end lg:pr-16 lg:pt-0 " +
          (resultsOpen ? " z-10 lg:z-10" : " z-20")
        }
      >
        {appInstalled ? (
          <a
            href="https://homes.ula.africa/app"
            className=" rounded-full   bg-ula-secondary px-16 py-4 font-semibold text-gray-150 shadow-md transition duration-500 hover:-translate-y-2    hover:text-gray-100 hover:shadow-2xl hover:shadow-ula-primary lg:border lg:bg-white lg:text-gray-900 "
          >
            Open app
          </a>
        ) : (
          <>
            {!resultsOpen ? (
              <div className=" absolute bottom-8 right-16 z-10 hidden h-88    w-72 flex-col rounded-md shadow-md lg:flex">
                <div className="  flex h-[70%] flex-col items-center justify-center rounded-t-md bg-gray-200">
                  <div className=" relative h-40   w-40 rounded-md bg-white shadow-2xl ">
                    <Image
                      className=" h-full w-full object-contain "
                      layout="fill"
                      src="/image/store-link.png"
                      alt="Ula Africa app Store Link"
                    />
                  </div>
                </div>
                <div className=" flex h-[50%] flex-col justify-center space-y-2 rounded-b-md bg-ula-secondary px-5 ">
                  <div className=" flex flex-col space-y-1">
                    <span className=" font-semibold text-gray-200">
                      Find homes easily, anywhere
                    </span>
                    <span className=" text-sm text-gray-200">
                      Use your phone’s camera to scan the QR code above and get
                      the Ula app
                    </span>
                  </div>
                  <Link href="/ula-app" passHref>
                    <a className="  cursor-pointer  font-semibold text-gray-300 hover:underline">
                      Learn more
                    </a>
                  </Link>
                </div>
              </div>
            ) : null}

            {/* <a
              href="https://homes.ula.africa/app"
              className=" flex rounded-full bg-ula-secondary  px-16 py-4 font-semibold text-gray-150 shadow-md transition duration-500 hover:-translate-y-2 hover:text-gray-100    hover:shadow-2xl hover:shadow-ula-primary lg:hidden lg:border lg:bg-white lg:text-gray-900 "
            >
              Get the app
            </a> */}
          </>
        )}
      </div>

      <Toast.Provider swipeDirection="right">
        <Toast.Root
          duration={5800}
          className=" flex flex-row justify-between space-x-0 rounded-lg border  border-green-100 bg-green-100 px-6 py-4 shadow-lg"
          open={openSuccess}
          onOpenChange={() => {
            setOpenSuccess(false);
            setError(null);
          }}
        >
          <AnimatePresence>
            {openSuccess && (
              <motion.div
                initial={{ opacity: 0, y: 20, scale: 0.7 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{
                  opacity: 0,
                  scale: 0.5,
                  transition: { duration: 0.6 },
                }}
                className="flex flex-row"
              >
                <div className=" flex flex-col space-y-0">
                  <Toast.Title className="  mb-0 text-base font-semibold text-green-700 ">
                    {error.errorCode}
                  </Toast.Title>
                  <Toast.Description asChild>
                    <span className=" text-sm text-green-700">
                      {error.errorMessage}
                    </span>
                  </Toast.Description>
                </div>
                <Toast.Action altText="Close">
                  <button
                    onClick={() => {
                      setOpen(false);
                    }}
                    className=" invisible ml-8 rounded-md border  border-green-200 bg-transparent px-4 py-1 text-xs font-semibold text-black "
                  >
                    Close
                  </button>
                </Toast.Action>
                <Toast.Close />
              </motion.div>
            )}
          </AnimatePresence>
        </Toast.Root>

        <Toast.Viewport className=" fixed bottom-0 right-0 mb-5 flex w-full max-w-screen-lg flex-col px-2 lg:mr-10 lg:w-auto lg:gap-10 lg:px-0" />
      </Toast.Provider>

      <Toast.Provider swipeDirection="right">
        <Toast.Root
          duration={2800}
          className=" flex flex-row justify-between space-x-0 rounded-lg border  border-red-100 bg-red-100 px-6 py-4 shadow-lg"
          open={open}
          onOpenChange={() => {
            setOpen(false);
            setError(null);
          }}
        >
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0, y: 20, scale: 0.7 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{
                  opacity: 0,
                  scale: 0.5,
                  transition: { duration: 0.6 },
                }}
                className="flex flex-row"
              >
                <div className=" flex flex-col space-y-0">
                  <Toast.Title className="  mb-0 text-base font-semibold text-red-600 ">
                    {error.errorCode}
                  </Toast.Title>
                  <Toast.Description asChild>
                    <span className=" text-sm text-red-600">
                      {error.errorMessage}
                    </span>
                  </Toast.Description>
                </div>
                <Toast.Action altText="Close">
                  <button
                    onClick={() => {
                      setOpen(false);
                    }}
                    className=" invisible ml-8 rounded-md border  border-red-200 bg-transparent px-4 py-1 text-xs font-semibold text-black "
                  >
                    Close
                  </button>
                </Toast.Action>
                <Toast.Close />
              </motion.div>
            )}
          </AnimatePresence>
        </Toast.Root>

        <Toast.Viewport className=" fixed bottom-0 right-0 mb-5 flex w-full max-w-screen-lg flex-col px-2 lg:mr-10 lg:w-auto lg:gap-10 lg:px-0" />
      </Toast.Provider>
    </div>
  );
}

export const getStaticProps = async () => {
  const ref = rtdbAdmin.ref();
  const allTowns = [];
  const towns = [];
  const popularTowns = [];
  await dbAdmin
    .collection("Towns")
    .where("popular", "==", true)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const townID = doc.id;
        popularTowns.push(
          Object.assign(
            {
              townID,
            },
            data
          )
        );
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });
  let featuredTown;
  await dbAdmin
    .collection("Towns")
    .where("isFeaturedOnly", "==", true)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const townID = doc.id;

        featuredTown = Object.assign(
          {
            townID,
          },
          data
        );
      });
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
    });

  let featuredTownProps = [];
  // console.log(featuredTown.townID);
  const res = await dbAdmin
    .collection("Properties")
    .where("status", "==", "ACTIVE")
    .where("propertyTown.townID", "==", featuredTown.townID)
    // .orderBy("inputData.addedTime", "asc")
    .orderBy("monthlyRent", "desc")
    .limit(4)
    .get()
    .catch((err) => {
      console.log(err);
      throw err;
    });

  // console.log(res)

  res.forEach((doc) => {
    const data = doc.data();
    const propertyID = doc.id;
    featuredTownProps.push(
      Object.assign(
        {
          propertyID,
        },
        data
      )
    );
  });

  const snapshot = await ref.child("Properties").get();
  if (!snapshot.exists()) {
    return "No Properties";
  } else {
    const val = snapshot.val();
    for (const key in val) {
      if (key) {
        const property = val[key];
        const propertyID = key;

        if (!towns.includes(property.propertyTown.townSlug)) {
          // avoid repetition of towns
          if (property.status === "ACTIVE") {
            allTowns.push(property.propertyTown);
          }
        }
        towns.push(property.propertyTown.townSlug);
      }
    }
  }

  // console.log(featureTownProps);
  return {
    props: {
      allTowns,
      popularTowns: JSON.stringify(popularTowns),
      featuredTown: JSON.stringify(featuredTown),
      featuredTownProps: JSON.stringify(featuredTownProps),
    },
    revalidate: 3600,
  };
};
